<template>
  <div class="dropdown-container">
    <BaseText size="md" :color="NebraskaColors.textSecondary">
      {{ data.title }}
    </BaseText>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import BaseText from '@/components/base-text/BaseText.vue';
import { DropdownItem } from '../types';

@Component({ components: { BaseText } })
export default class DropdownSectionItem extends Vue {
  @Prop({ type: Object, required: true })
  public readonly data!: DropdownItem;

  readonly NebraskaColors = NebraskaColors;
}
</script>

<style lang="less" scoped>
.dropdown-container {
  padding: 0 @size-spacing-x300 @size-spacing-x300 @size-spacing-x300;
  padding-top: @size-spacing-x300;

  &:not(:first-of-type) {
    padding-top: @size-spacing-x500;
  }

  p {
    font-weight: bold;
  }
}
</style>
