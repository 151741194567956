<template>
  <Row :tabindex="0" :has-line="false" class="empty-instruments__row">
    <template #top-left>
      <div class="empty-instruments__row--item">
        <BaseIcon
          icon="ic_search_empty"
          :colors="NebraskaColors.elementPlaceholder"
        />
        <p class="text-p-3 empty-instruments__row--item__text">
          Busca não encontrada. Veja se digitou corretamente
        </p>
      </div>
    </template>
  </Row>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import Row from '@/components/row/Row.vue';

@Component({
  components: {
    Row,
    BaseIcon
  }
})
export default class DropdownEmptyState extends Vue {
  readonly NebraskaColors = NebraskaColors;
}
</script>

<style lang="less" scoped>
.empty-instruments {
  &__row {
    width: 100%;

    &--item {
      display: flex;
      align-items: center;
      padding: 0 @size-spacing-x300;

      &__text {
        color: @text-secondary;
        margin: 0 @size-spacing-x350;
      }
    }
  }
}
</style>
