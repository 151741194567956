<template>
  <button
    id="dropdown-option"
    type="button"
    data-testid="dropdown-option"
    class="dropdown-option__button"
    @click="emitOptionClick"
    @keydown.enter="emitOptionClick"
  >
    <div
      :class="{
        'dropdown-option__button__inner': true,
        'dropdown-option__button__inner--width-close': data.showRemove
      }"
    >
      <img
        v-if="!!data.image"
        :src="data.image"
        alt=""
        class="dropdown-option__button__inner__image"
        data-testid="dropdown-option-image"
      />
      <div class="dropdown-option__button__inner__text">
        <BaseText size="lg" :color="NebraskaColors.textPrimary">
          {{ data.title }}
        </BaseText>
        <BaseText
          v-if="!!data.description"
          size="sm"
          :color="NebraskaColors.textSecondary"
        >
          {{ data.description }}
        </BaseText>
      </div>
    </div>
    <ButtonIcon
      v-if="data.showRemove"
      id="dropdown-option-close"
      :label="`Excluir opção ${data.title}`"
      icon="EF0030"
      :size="ButtonSizes.SMALL"
      :colors="closeButtonColors"
      @click.stop="emitRemoveOption"
    />
  </button>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import BaseText from '@/components/base-text/BaseText.vue';
import ButtonIcon from '@/foundation/button-icon/ButtonIcon.vue';
import { ButtonSizes } from '@/foundation/types';
import { DropdownItem } from '../types';

@Component({
  components: {
    BaseText,
    ButtonIcon
  }
})
export default class DropdownOptionItem extends Vue {
  @Prop({ type: Object, required: true })
  public readonly data!: DropdownItem;

  @Emit('option-click')
  public emitOptionClick() {
    return this.data;
  }

  @Emit('remove-option')
  public emitRemoveOption() {
    return this.data;
  }

  readonly NebraskaColors = NebraskaColors;

  readonly ButtonSizes = ButtonSizes;

  public closeButtonColors = {
    background: NebraskaColors.elementSecondary,
    content: NebraskaColors.elementSecondary,
    alphas: {
      default: 0,
      hover: 0.08,
      active: 0.08
    }
  };
}
</script>

<style lang="less" scoped>
.dropdown-option {
  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background: @background-secondary;
    padding: 0px @size-spacing-x300;
    width: 100%;
    border-radius: @size-radius-x200;
    border: 0;

    &:hover,
    &:focus {
      background: @background-hover;
      outline: none;
    }

    &__inner {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: @size-spacing-x350 0px;
      width: 100%;

      &--width-close {
        width: calc(100% - @size-spacing-x700);
      }

      &__text {
        text-align: left;
      }

      &__image {
        width: 24px;
        height: 24px;
        border-radius: @size-radius-x300;
        margin-right: @size-spacing-x400;
      }
    }
  }
}
</style>
