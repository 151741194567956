<template>
  <div class="dropdown__wrapper">
    <div
      ref="dropdownContainer"
      class="dropdown__content"
      @keydown.esc.prevent="emitCloseDropdown"
      v-on="$listeners"
    >
      <DropdownInputValueItem
        v-if="inputValue"
        ref="inputValue"
        :input-value="inputValue"
        @input-value-click="emitInputValueClick"
      />
      <div
        v-if="isLoading"
        class="dropdown__loader"
        data-testid="dropdown-loading"
      >
        <SpinnerLoader :color="NebraskaColors.elementPlaceholder" :size="24" />
      </div>
      <DropdownEmptyState
        v-else-if="isEmpty"
        class="dropdown__empty"
        data-testid="dropdown-empty"
      />
      <div
        v-else-if="hasItemsAvailable"
        data-testid="dropdown"
        :style="{ visibility: isCalculating ? 'hidden' : 'visible' }"
      >
        <component
          :is="itemComponentsByType[item.type]"
          v-for="item in itemsAvailable"
          ref="dropdownItems"
          :key="item.title"
          :data="item"
          @option-click="emitOptionClick"
          @remove-option="emitRemoveOption"
        >
          {{ item.type }}
        </component>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Ref, Watch, Emit } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import { SpinnerLoader } from '@/components/spinner-loader';

import DropdownOptionItem from './dropdown-option-item/DropdownOptionItem.vue';
import DropdownInputValueItem from './dropdown-input-value-item/DropdownInputValueItem.vue';
import DropdownSectionItem from './dropdown-section-item/DropdownSectionItem.vue';
import DropdownEmptyState from './dropdown-empty-state/DropdownEmptyState.vue';

import { DropdownItemTypes, DropdownItem } from './types';

@Component({
  components: {
    DropdownOptionItem,
    DropdownInputValueItem,
    DropdownSectionItem,
    SpinnerLoader,
    DropdownEmptyState
  }
})
export default class Dropdown extends Vue {
  /**
   * O texto exibido dentro do campo de busca
   */
  @Prop({ type: [String, Number], required: false })
  public readonly inputValue?: string | number;

  /**
   * O tamanho máximo do componente. Caso a tela seja menor, é respeitado o tamanho da tela
   */
  @Prop({ type: Number, required: true })
  private readonly maxHeight!: number;

  /**
   * A lista de itens exibida no componente
   */
  @Prop({ type: Array, required: false })
  private readonly items?: DropdownItem[];

  /**
   * Determina se deve ser exibido o estado de carregamento
   */
  @Prop({ type: Boolean, default: false })
  public readonly isLoading!: boolean;

  /**
   * Evento chamado quando o campo de busca é clicado
   */
  @Emit('input-value-click')
  public emitInputValueClick(_inputValue: string) {
    // Função que emite evento input-value-click
  }

  /**
   * Evento chamado quando um dos itens é clicado
   */
  @Emit('option-click')
  public emitOptionClick(_data: DropdownItem) {
    // Função que emite evento option-click
  }

  /**
   * Evento chamado quando devemos fechar o componente
   */
  @Emit('close')
  public emitCloseDropdown() {
    // Função que emite evento
  }

  /**
   * Evento chamado quando é clicado o botão de remoção de item
   */
  @Emit('remove-option')
  public emitRemoveOption(_option: DropdownItem) {
    // Função que emite evento remove-option
  }

  @Ref('dropdownContainer')
  private dropdownContainer!: HTMLDivElement;

  @Ref('inputValue')
  private inputValueComponent!: Vue;

  @Ref('dropdownItems')
  private dropdownItems?: Vue[];

  public itemsAvailable: DropdownItem[] = [];

  public isCalculating = true;

  readonly NebraskaColors = NebraskaColors;

  public itemComponentsByType = {
    [DropdownItemTypes.OPTION]: DropdownOptionItem,
    [DropdownItemTypes.SECTION]: DropdownSectionItem
  };

  @Watch('items', { immediate: true })
  private watchItems(value: DropdownItem[]) {
    this.itemsAvailable = value;
    if (value && value.length > 0) {
      this.isCalculating = true;
      this.$nextTick(() => {
        this.calculateItemsToRender();
      });
    }
  }

  public get isEmpty() {
    return this.items && this.items.length === 0;
  }

  public get hasItemsAvailable() {
    return this.itemsAvailable && this.itemsAvailable.length > 0;
  }

  private getAvailableHeight() {
    const windowHeight = window.innerHeight;
    const dropdownContainerRect =
      this.dropdownContainer.getBoundingClientRect();
    const hasSpaceToUseMaxHeight =
      windowHeight >= this.maxHeight + dropdownContainerRect.top;

    if (hasSpaceToUseMaxHeight) {
      return this.maxHeight;
    } else {
      return windowHeight - dropdownContainerRect.top;
    }
  }

  private getInitialHeight() {
    let initialHeight = 0;
    const dropdownContainerStyle = window.getComputedStyle(
      this.dropdownContainer
    );
    const dropdownContainerPaddingTop =
      parseFloat(dropdownContainerStyle.getPropertyValue('padding-top')) || 0;
    const dropdownContainerPaddingBottom =
      parseFloat(dropdownContainerStyle.getPropertyValue('padding-bottom')) ||
      0;

    if (this.inputValueComponent) {
      const searchedValueElmRect =
        this.inputValueComponent.$el.getBoundingClientRect();
      initialHeight += searchedValueElmRect.height;
    }
    initialHeight +=
      dropdownContainerPaddingTop + dropdownContainerPaddingBottom;

    return initialHeight;
  }

  private calculateItemsToRender() {
    let newItems: DropdownItem[] = [];
    let currentHeightUsed = this.getInitialHeight();
    const availableHeight = this.getAvailableHeight();

    this.dropdownItems &&
      this.dropdownItems.forEach((component: Vue, index: number) => {
        const element = component.$el as HTMLDivElement;
        const elementRect = element.getBoundingClientRect();
        const hasHeightToFitItem =
          currentHeightUsed + elementRect.height <= availableHeight;

        if (hasHeightToFitItem && this.items) {
          newItems.push(this.items[index]);
        }
        currentHeightUsed += elementRect.height;
      });

    const lastItemType = newItems[newItems.length - 1].type;
    if (lastItemType === DropdownItemTypes.SECTION) {
      newItems.pop();
    }
    if (newItems.length < 2 && this.items && this.items.length > 1) {
      newItems = this.items.slice(0, 2);
    }

    this.itemsAvailable = newItems;
    this.isCalculating = false;
  }
}
</script>

<style lang="less" scoped>
.dropdown {
  &__content {
    height: auto;
    background-color: @background-secondary;
    padding: @size-spacing-x400 @size-spacing-x300;
    border-radius: @size-radius-x100;
    border: @size-border-x400 solid @divider-primary;
    box-shadow: 0px 18px 40px rgba(47, 47, 51, 0.14);
    margin-top: @size-spacing-x150;
    overflow: hidden;
  }

  &__wrapper {
    position: absolute;
    width: 100%;
    z-index: 10;
  }

  &__loader {
    display: flex;
    justify-content: center;
    margin: @size-spacing-x300 0;
  }

  &__empty {
    padding: 0 @size-spacing-x300;
  }
}
</style>
