<template>
  <InputFoundation
    :id="id"
    ref="search-bar-input"
    v-model="inputValue"
    :aria-label="arialLabel"
    :value="value"
    :type="type"
    :readonly="readonly"
    :disabled="disabled"
    :required="required"
    :autocomplete="autocomplete"
    :size="size"
    :placeholder="placeholder"
    @focus="toggleFocus"
    @blur="toggleFocus"
    @keydown.enter.prevent="searchText"
    v-on="$listeners"
  >
    <template #leading-slot>
      <BaseIcon icon="EE0150" />
    </template>
    <template #trailing-slot>
      <ButtonIconContextual
        v-if="inputValue"
        icon="EF0031"
        size="small"
        label="Limpar input"
        :disabled="isDisabled"
        @click="emitOnClear"
      />
    </template>
    <template #bottom-slot>
      <Dropdown
        v-if="showDropdown"
        :input-value="inputValue"
        :max-height="466"
        :items="dropdownItems"
        :is-loading="isLoading"
        @input-value-click="emitSearchInput"
        @option-click="emitSearchOption"
        @close="toggleFocus"
        @remove-option="emitRemoveOption"
      />
      <div v-else />
    </template>
  </InputFoundation>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit, Ref } from 'vue-property-decorator';

import InputFoundation from '@/foundation/input/InputFoundation.vue';
import { InputSizes } from '@/foundation/types';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import ButtonIconContextual from '@/components/button-icon/button-icon-contextual/ButtonIconContextual.vue';
import Dropdown from './dropdown/Dropdown.vue';
import { DropdownItem } from './dropdown/types';

@Component({
  components: {
    BaseIcon,
    ButtonIconContextual,
    InputFoundation,
    Dropdown
  }
})
export default class SearchBar extends Vue {
  @Ref('search-bar-input')
  private searchBarInput!: InputFoundation;

  /**
   * Label não visível utilizado para dar contexto as tecnologias assistivas
   */
  @Prop({ type: String, required: false })
  readonly arialLabel?: string;

  /**
   * Valor inicial do input
   */
  @Prop({ type: [String, Number], required: false })
  readonly value!: string | number;

  /**
   * O tipo do `input`, conforme especificação HTML. Em geral só usamos o type como search ou text para inputs de procura
   * @values text, search
   */
  @Prop({ type: String, default: 'text' })
  readonly type!: string;

  /**
   * Caso o input seja somente leitura (permite selecionar o valor)
   */
  @Prop({ type: Boolean, required: false, default: false })
  readonly readonly!: boolean;

  /**
   * Caso o input esteja desativado
   */
  @Prop({ type: Boolean, required: false, default: false })
  readonly disabled!: boolean;

  /**
   * O texto exibido dentro do input, quando não há valor digitado
   */
  @Prop({ type: String, required: false })
  readonly placeholder?: string;

  /**
   * O atributo usado para indicar que a entrada do usuário é obrigatória.
   */
  @Prop({ type: Boolean, required: false, default: false })
  readonly required?: boolean;

  /**
   * O id está relacionado diretamente com o autocomplete, se você optar por não passar um id, consequentemente não irá funcionar o autocomplete.
   * [Nessa documentação](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#values) poderá ver alternativas do que passar no id para que as sugestões do autocomplete estejam de acordo com o desejado.
   * Você pode também utilizar o "off" para que não seja exibida as sugestões do autocomplete.
   * @values off
   */
  @Prop({ type: String, required: false, default: 'on' })
  readonly autocomplete?: string;

  /**
   * Se não passado nenhum id, ele será gerado aleatóriamente.
   */
  @Prop({ type: String, required: false })
  readonly id?: string;

  /**
   * Tamanhos para input
   * @values small, regular
   */
  @Prop({ type: String, default: InputSizes.REGULAR })
  readonly size!: string;

  /**
   * Determina se a busca pode ser realizada dentro do dropdown
   */
  @Prop({ type: Boolean, default: false })
  readonly isDropdownSearch!: boolean;

  /**
   * Itens exibidos no dropdown
   */
  @Prop({ type: Array, default: [] })
  readonly dropdownItems!: DropdownItem[];

  /**
   * Determina se o estado do dropdown deve ser o de carregando
   */
  @Prop({ type: Boolean, default: false })
  readonly isLoading!: boolean;

  /**
   * Evento disparado quando o value do input é alterado
   */
  @Emit('input')
  public emitInput(_value: string | number) {
    // Função que emite evento input
  }

  /**
   * Evento disparado quando o texto de search é clicado
   */
  @Emit('search-input')
  public emitSearchInput(_value: string | number) {
    this.toggleFocus();
  }

  @Emit('search-input')
  public searchText({ target }: { target: HTMLInputElement }) {
    target && target.blur();
    return this.inputValue;
  }

  /**
   * Evento disparado quando uma opção de busca é clicada
   */
  @Emit('search-option')
  public emitSearchOption(_option: DropdownItem) {
    this.toggleFocus();
  }

  /**
   * Evento disparado quando é clicado o botão de remoção de alguma opção
   */
  @Emit('remove-option')
  public emitRemoveOption(_option: DropdownItem) {
    this.searchBarInput.$el.getElementsByTagName('input')[0].focus();
  }

  /**
   * Evento disparado quando o botão de limpar o input recebe um clique
   */
  @Emit('on-clear')
  public emitOnClear(_value: string | number) {
    // Função que emite evento on-clear
  }

  private isFocused = false;

  get isDisabled() {
    return this.disabled || this.readonly;
  }

  get inputValue() {
    return this.value;
  }

  set inputValue(value) {
    this.emitInput(value);
  }

  get showDropdown() {
    return (
      this.isDropdownSearch &&
      this.isFocused &&
      (this.dropdownItems.length || this.inputValue)
    );
  }

  public toggleFocus(event?: MouseEvent) {
    if (
      event &&
      event.relatedTarget &&
      ((event.relatedTarget as HTMLElement).id === 'dropdown-option' ||
        (event.relatedTarget as HTMLElement).id === 'dropdown-option-close' ||
        (event.relatedTarget as HTMLElement).id === 'dropdown-input-value')
    )
      return;
    this.isFocused = !this.isFocused;
  }
}
</script>
