<template>
  <div
    :class="[
      'signin',
      {
        'signin--gradient': gradientBackground,
        'signin--tablet': tabletQuery,
        'signin--slide': slideAnimation
      }
    ]"
    :style="computedStyles"
  >
    <NavBar v-if="showBackButton" class="signin-navbar" transparent>
      <template #left>
        <ButtonIcon
          icon="ic_arrow_left"
          size="small"
          label="Voltar"
          :colors="buttonColors"
          @click="onBack"
        />
      </template>
    </NavBar>
    <Grid class="signin-template" :gutter="0" :columns="[1, 1]">
      <div class="signin-container signin-container--left">
        <header class="signin-container__content signin-header">
          <slot name="header" />
        </header>
      </div>
      <div class="signin-container signin-container--right">
        <div class="signin-container__content">
          <slot name="default" />
        </div>
      </div>
    </Grid>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import { Grid } from '@/components/grid';
import { NavBar } from '@/components/navbar';
import { ButtonIconFoundation as ButtonIcon } from '@/foundation';
import { overPrimary } from '@/styles/Button';

@Component({ name: 'SigninTemplate', components: { ButtonIcon, Grid, NavBar } })
export default class SigninTemplate extends Vue {
  @Prop({
    type: String,
    required: false,
    default: NebraskaColors.themeOverPrimary
  })
  private readonly overPrimary!: string;

  @Prop({ type: String, required: false })
  private readonly primary?: string;

  /**
   *  Mostra o botão de voltar no topo esquerdo do componente
   */
  @Prop({ type: Boolean, required: false, default: false })
  private readonly showBackButton!: boolean;

  /**
   *  Coloca o background como a animação de gradiente usada na plataforma
   */
  @Prop({ type: Boolean, required: false, default: false })
  private readonly gradientBackground!: boolean;

  /**
   *  Adiciona uma visualização pra tablet onde o grid é quebrado em dois blocos um embaixo do outro
   */
  @Prop({ type: Boolean, required: false, default: false })
  private readonly tabletQuery!: boolean;

  /**
   *  Adiciona uma animação de slide para o bloco da direita (apenas no desktop)
   */
  @Prop({ type: Boolean, required: false, default: false })
  private readonly slideAnimation!: boolean;

  private NebraskaColors = NebraskaColors;

  private buttonColors = overPrimary;

  @Emit('on-back-click')
  private onBack() {
    // Função que emite evento on-back-click
  }

  private get computedStyles() {
    if (this.primary && this.overPrimary) {
      return {
        '--theme-primary': this.primary,
        '--theme-over-primary': this.overPrimary
      };
    }
    return undefined;
  }
}
</script>

<style lang="less" scoped>
@keyframes gradient-background-full {
  0%,
  100% {
    background: url('https://warren.com.br/assets/gradient-animation/morning/web_full_raw.jpg');
    background-size: cover;
  }
  25% {
    background: url('https://warren.com.br/assets/gradient-animation/day/web_full_raw.jpg');
    background-size: cover;
  }
  50% {
    background: url('https://warren.com.br/assets/gradient-animation/evening/web_full_raw.jpg');
    background-size: cover;
  }
  75% {
    background: url('https://warren.com.br/assets/gradient-animation/night/web_full_raw.jpg');
    background-size: cover;
  }
}

@keyframes gradient-background-regular {
  0%,
  100% {
    background: url('https://warren.com.br/assets/gradient-animation/morning/web_regular.jpg');
    background-size: cover;
  }
  25% {
    background: url('https://warren.com.br/assets/gradient-animation/day/web_regular.jpg');
    background-size: cover;
  }
  50% {
    background: url('https://warren.com.br/assets/gradient-animation/evening/web_regular.jpg');
    background-size: cover;
  }
  75% {
    background: url('https://warren.com.br/assets/gradient-animation/night/web_regular.jpg');
    background-size: cover;
  }
}

@keyframes right-slide {
  0% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.signin {
  position: relative;

  &--gradient {
    animation: gradient-background-regular 60s infinite linear;
    height: fit-content;
    width: 100%;
    overflow: hidden;

    &::before {
      content: '';
      width: 0;
      height: 0;
      visibility: hidden;
      animation: gradient-background-regular 1s infinite linear;
    }

    @media screen and (max-width: 1023px) {
      animation: none;
      background: url('https://warren.com.br/assets/gradient-animation/morning/web_regular.jpg');
      background-size: cover;
    }

    @media screen and (max-height: 1023px) and (max-width: 1023px) {
      overflow: visible;
    }
  }

  &--slide {
    @media screen and (min-width: 1024px) {
      overflow: hidden;
    }
  }

  &-navbar {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    border: none;
    width: 100%;

    ::v-deep .navbar-container--transparent {
      border: none;
    }
  }

  &-template {
    width: 100vw;
    height: 100vh;

    @media screen and (max-width: 768px) {
      grid-template-rows: 180px 1fr !important;
    }

    .signin.signin--tablet & {
      flex-direction: column;

      @media screen and (max-width: 1023px) {
        grid-template-columns: 1fr !important;
        grid-template-rows: auto !important;
      }
    }
  }

  &-container {
    display: flex;
    position: relative;

    &--left {
      justify-content: flex-end;
      background-color: @theme-primary;
      transition: background-color 0.5s ease-out;

      .signin.signin--gradient & {
        background-color: transparent;
        transition: none;
      }
    }

    &--right {
      justify-content: flex-start;
      background-color: @background-secondary;

      @media screen and (min-width: 1024px) {
        .signin.signin--slide & {
          animation: right-slide 1.5s ease-out;
        }
      }
    }

    &__content {
      max-width: 860px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &-header {
      color: @text-negative;
      padding: 0 @size-spacing-x400;
      text-align: center;
    }

    &-back {
      background: transparent;
      border: none;
      cursor: pointer;
      padding: 0;
    }
  }
}
</style>
