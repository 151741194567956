<template>
  <button
    id="dropdown-input-value"
    type="button"
    class="dropdown-input-value__button"
    data-testid="dropdown-input-value"
    @click="emitInputValueClick"
    @keydown.enter="emitInputValueClick"
  >
    <div class="dropdown-input-value__inner">
      <BaseIcon
        aria-hidden="true"
        icon="ic_search"
        :colors="NebraskaColors.elementSecondary"
      />
      <BaseText size="lg" :color="NebraskaColors.textPrimary">
        {{ inputValue }}
      </BaseText>
    </div>
  </button>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import BaseText from '@/components/base-text/BaseText.vue';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';

@Component({
  components: {
    BaseIcon,
    BaseText
  }
})
export default class DropdownInputValueItem extends Vue {
  @Prop({ type: [String, Number], required: true })
  public readonly inputValue!: string | number;

  @Emit('input-value-click')
  public emitInputValueClick() {
    return this.inputValue;
  }

  readonly NebraskaColors = NebraskaColors;
}
</script>

<style lang="less" scoped>
.dropdown-input-value {
  &__button {
    width: calc(100% - @size-spacing-x400);
    cursor: pointer;
    margin: 0 @size-spacing-x300 @size-spacing-x400 @size-spacing-x300;

    background-color: @background-tertiary;
    border-radius: @size-radius-x200;
    border: 0;

    &:hover,
    &:focus {
      background: @background-hover;
      outline: none;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    padding: @size-spacing-x350 @size-spacing-x150;
    width: calc(100% - @size-spacing-x300);

    p {
      margin-left: @size-spacing-x300;
      font-weight: bold;
    }
  }
}
</style>
