<template>
  <Button
    :class="computedClassName"
    :is-blur="isBlur"
    :tabindex="tabindex"
    :href="href"
    :disabled="disabled"
    :loading="loading"
    :colors="colors"
    data-testid="button-label"
    v-on="$listeners"
  >
    <span class="content">
      <BaseIcon v-if="icon" class="icon" colors="currentColor" :icon="icon" />
      <span class="label"><slot /></span>
    </span>
  </Button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import Button from '@/foundation/button/Button.vue';
import { ButtonColors } from '../models/Button';
import { ButtonSizes, IconPosition } from '../types';

@Component({
  name: 'ButtonLabel',
  components: { BaseIcon, Button }
})
export default class ButtonLabel extends Vue {
  /**
   * Permite atribuir um link ao botão
   */
  @Prop({ type: String })
  readonly href?: string;

  /**
   * Desativa o botão
   */
  @Prop({ type: Boolean })
  readonly disabled?: boolean;

  /**
   * Coloca o botão no estado "carregando"
   */
  @Prop({ type: Boolean })
  readonly loading?: boolean;

  /**
   * Define as cores do botão
   */
  @Prop({
    type: Object
  })
  readonly colors?: ButtonColors;

  /**
   * Define qual a posição do elemento durante a navegação pelo teclado. Na maioria dos casos, não é necessário alterar o valor padrão.
   * @see {@link https://developer.mozilla.org/pt-BR/docs/Web/HTML/Global_attributes/tabindex}
   */
  @Prop({ type: Number })
  readonly tabindex?: number;

  /**
   * Define o tamanho do botão
   * @values regular, large
   */
  @Prop({
    type: String,
    default: ButtonSizes.REGULAR
  })
  readonly size!: string;

  /**
   * O ícone a ser exibido
   */
  @Prop({
    type: String
  })
  readonly icon?: string;

  /**
   * A posição do ícone
   * @values left, right
   */
  @Prop({
    type: String,
    default: IconPosition.LEFT
  })
  readonly iconPosition?: IconPosition;

  /**
   * Estilo de Blur
   */
  @Prop({
    type: Boolean,
    default: false
  })
  readonly isBlur?: boolean;

  private get computedClassName() {
    return [
      'button-label',
      this.size,
      {
        [`icon-${this.iconPosition}`]: this.icon
      }
    ];
  }
}
</script>

<style lang="less" scoped>
.button-label {
  border-radius: @size-radius-x200;
  line-height: 24px;
  padding: @size-spacing-x350;

  &.large {
    padding-top: @size-spacing-x450;
    padding-bottom: @size-spacing-x450;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon {
    line-height: 0;
  }

  @iconMargin: @size-spacing-x300;

  &.icon-left .icon {
    margin-right: @iconMargin;
  }

  &.icon-right {
    .icon {
      margin-left: @iconMargin;
    }

    .content {
      flex-direction: row-reverse;
    }
  }
}
</style>
